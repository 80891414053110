@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&display=swap');
body {
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */

  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: #fff;
}

*,
body {
  margin: 0;
  padding: 0;
}
a {
  color: #00f;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0 0 0 20px;
  line-height: 24px;
}

h1 {
  margin: 30px 0 20px;
  font-size: 32px;
  font-weight: 900;
  color: #000;
  line-height: 48px;
}

h1,
h2 {
  font-weight: 900;
  color: #000;
}

h2 {
  margin: 30px 0 10px;
  font-size: 26px;
}

p {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.8rem;
  line-height: 22px;
  font-size: 17px;
}

.container {
  padding: 0 20px;
}

.container,
.main-navigation {
  max-width: 1200px;
  margin: auto;
}

.main-navigation {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 0;
}

.main-navigation a {
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  margin: 5px 30px 5px 0;
  text-transform: capitalize;
  padding: 0 0 2px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.main-navigation a:last-child {
  margin-right: 0;
}

.main-navigation a.active,
.main-navigation a:hover {
  color: yellow;
}
.header-contact {
  background: #c70f15;
  padding: 10px;
}
.header-top {
  width: 100%;
  padding: 10px 0 8px;
  background: #fff;
}

.header {
  display: flex;
  max-width: 1200px;
  padding: 0 20px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.header-bottom {
  padding: 0 20px;
  background: #c70f15;
}
.header-contact .header {
  justify-content: flex-start;
}
.contact-numbers {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  align-items: center;
}
.call-for-pickup {
  color: #000;
  background: #fff;
  padding: 2px 15px;
  font-size: 22px;
  font-weight: 900;
  margin: 0px 20px 0px 0px;
  border-radius: 3px;
}
.contact-numbers a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  margin-right: 30px;
}

.contact-numbers a svg {
  color: yellow;
  margin: 0px 5px 0 0;
  font-size: 32px;
}
.contact-numbers p {
  margin: 0px;
  background: #000;
  padding: 5px 20px;
  border-radius: 6px;
  color: #fff;
  border: 1px solid #fff;
}
.courier-services {
  display: flex;
  justify-content: space-between;
  margin: 0;
  border-bottom: 1px dashed #9f4b88;
  padding: 0 0 10px;
}
.content {
  padding: 10px 0px 0px 0px;
}
.courier-services article {
  width: calc(33.3% - 20px);
  margin-bottom: 20px;
}

.courier-services img {
  width: 100%;
  margin: 0 0 0px;
  border-radius: 10px;
  border: 2px solid #a7a76a;
  padding: 5px;
  box-sizing: border-box;
}

.courier-services h2 {
  margin: 0 0 5px;
  font-weight: 900;
  font-size: 26px;
  color: #6f09de;
}
.guaranteed {
  color: yellow;
  font-size: 20px;
}

.medicine-service {
  border-radius: 10px;
  border: 2px solid #a7a76a;
  padding: 5px;
  box-sizing: border-box;
  max-width: 100%;
  margin-bottom: 20px;
}

.toggle-menu {
  display: block;
  width: 100%;
}

.nav-toggle {
  background: transparent;
  border: transparent;
  font-size: 30px;
  color: #000;
  cursor: pointer;
  transition: #00f;
  display: none;
  margin-top: 10px;
  float: right;
}

.nav-toggle:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

#close-btn {
  position: absolute;
  top: 8px;
  right: 20px;
  font-size: 30px;
  color: #333;
  cursor: pointer;
  display: none;
}
.image-right-content,
.image-left-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}
.image-right-content .col-left,
.image-left-content .col-right {
  width: 60%;
}
.image-right-content .col-right,
.image-left-content .col-left {
  width: 40%;
}
.col-right img,
.col-left img {
  width: 100%;
}
@media (max-width: 1040px) {
  .contact-numbers p {
    margin: 5px 0px 0px 0px;
  }
}
@media (max-width: 768px) {
  .courier-services {
    flex-wrap: wrap;
  }
  .main-navigation {
    display: none;
  }
  .courier-services article {
    width: calc(50% - 20px);
  }

  .contact-numbers a {
    font-size: 23px;
    margin-bottom: 5px;
  }
  .contact-numbers a,
  .call-for-pickup {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    /* float: right; */
  }
  .call-for-pickup {
    width: inherit;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 5px;
    /* float: right; */
  }
  .medicine-service {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
    border-radius: 10px;
  }

  .nav-toggle {
    display: block;
  }

  .main-navigation.show-menu {
    display: block;
  }

  .main-navigation a {
    display: block;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    margin: 5px 0;
    text-transform: capitalize;
    padding: 5px 0 7px 10px;
    border: none;
    font-weight: 900;
  }

  .main-navigation a:last-child {
    margin-right: 0;
  }

  .main-navigation a.active,
  .main-navigation a:hover {
    color: yellow;
    border-bottom: none;
  }

  #close-btn {
    display: block;
  }
  .image-right-content,
  .image-left-content {
    flex-wrap: wrap;
    gap: 0px;
  }
  .image-right-content .col-left,
  .image-right-content .col-right,
  .image-left-content .col-left,
  .image-left-content .col-right {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .courier-services {
    flex-wrap: wrap;
  }

  .courier-services article {
    width: 100%;
  }
}

.footer-container {
  background: #173753;
  /* background: #4f1640; */

  min-height: 200px;
  margin: 30px 0 0;
}

.footer-content {
  max-width: 1200px;
  padding: 0 20px 40px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
  font-size: 19px;
}

.footer-col h2 {
  border-bottom: 1px solid rgb(194, 194, 194);
  padding-bottom: 5px;
  color: #fff;
}

.footer-list {
  color: #fff;
  list-style: none;
  padding: 0;
}

.footer-list li {
  margin-bottom: 5px;
}

.footer-list li a {
  color: #fff;
  text-decoration: none;
}

.footer-navigation {
  display: flex;
  flex-direction: column;
}

.footer-navigation a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
}

.footer-navigation a.active,
.footer-navigation a:hover {
  color: yellowgreen;
}

.footer-content + .footer-content {
  padding-top: 20px;
  font-size: 18px;
}

.footer-content time {
  margin: 0 0 0 7px;
}

.form-field label {
  font-size: 14px;
  display: block;
}
address {
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-style: normal;
  line-height: 25px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.nri-medicine-courier {
  margin-top: 0px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin 20s linear infinite;
    animation: App-logo-spin 20s linear infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes App-logo-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
/*# sourceMappingURL=main.5bc7e076.chunk.css.map */
